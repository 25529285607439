import React, { useEffect, useState } from 'react'

import styles from './LinearLoading.module.scss'

type Props = {
  size: number
  onFinish?: () => void
  offset?: number
  customPercentage?: number
  animate?: boolean
  vertical?: boolean
}

const LinearLoading: React.FC<Props> = ({
  size,
  onFinish,
  offset = 1000,
  customPercentage,
  animate = false,
  vertical = false,
}) => {
  const [percentage, setPercentage] = useState(0)
  const [hiding, setHiding] = useState(false)
  useEffect(() => {
    if (typeof customPercentage === 'number' || animate) {
      return
    }
    setTimeout(() => setPercentage(100), offset)
    setTimeout(() => setHiding(true), offset + 700)
    onFinish && setTimeout(onFinish, offset + 1000)
  }, [])
  const percentageToUse = customPercentage || percentage
  const containerSize = hiding ? size / 4 : size
  const thumbSize = (size * percentageToUse) / 100
  return (
    <div
      className={styles.container}
      data-animate={animate}
      style={
        vertical
          ? { height: containerSize, opacity: hiding ? 0 : 1 }
          : { width: containerSize, opacity: hiding ? 0 : 1 }
      }
    >
      {animate ? (
        <div className={styles.thumb} data-animate={animate} />
      ) : (
        <div style={vertical ? { height: thumbSize } : { width: thumbSize }} className={styles.thumb} />
      )}
    </div>
  )
}

export default LinearLoading
