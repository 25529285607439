import React, { ReactNode, useEffect, useState } from 'react'

import PaymenResultModal from '../components/common/MarketingPayment/PaymenResultModal/PaymenResultModal'
import PaddleScript from '../components/scripts/Paddle/Paddle'
import useStorageState from '../hooks/useStorageState'
import { useCurrentUser } from './CurrentUserContext'

type FetchType = {
  email: string
  marketingEnabled: boolean
  isYearly: boolean
  selectedPlan: string | null

  setEmail: (v: string) => void
  setMarketingEnabled: (v: boolean | ((v: boolean) => boolean)) => void
  setIsYearly: (v: boolean | ((v: boolean) => boolean)) => void
  setSelectedPlan: (v: string | null) => void
  setIsPaymentSuccessful: (v: boolean) => void
}

export const PaddleContext = React.createContext<FetchType>({
  email: '',
  marketingEnabled: false,
  isYearly: false,
  selectedPlan: null,

  setEmail: () => {},
  setMarketingEnabled: () => {},
  setIsYearly: () => {},
  setSelectedPlan: () => {},
  setIsPaymentSuccessful: () => {},
})

export const PaddleProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useCurrentUser()

  // payment form fields
  const [email, setEmail] = useStorageState('', 'email')
  const [marketingEnabled, setMarketingEnabled] = useState(false)
  const [isYearly, setIsYearly] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null)
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false)

  useEffect(() => {
    if (currentUser) setEmail(currentUser.email)
  }, [currentUser])

  return (
    <PaddleContext.Provider
      value={{
        email,
        marketingEnabled,
        isYearly,
        selectedPlan,

        setEmail,
        setMarketingEnabled,
        setIsYearly,
        setSelectedPlan,
        setIsPaymentSuccessful,
      }}
    >
      <PaddleScript />
      <PaymenResultModal isOpen={isPaymentSuccessful} onClose={() => {}} status={'pending'} />
      {children}
    </PaddleContext.Provider>
  )
}

export const usePaddle = () => React.useContext<FetchType>(PaddleContext)
