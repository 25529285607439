import Script from 'next/script'
import React from 'react'

import { isEmailSubscribedInnerRoute } from '../../../api/auth'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { usePaddle } from '../../../context/PaddleContext'
import { trackStartTrial } from '../../../utils/gtag/gtagMetricsUtils'

const PaddleScript = () => {
  const { currentUser } = useCurrentUser()
  const { email, setIsPaymentSuccessful } = usePaddle()

  return (
    <Script
      src='https://cdn.paddle.com/paddle/paddle.js'
      strategy='lazyOnload'
      onLoad={() => {
        Paddle.Setup({
          vendor: 39907,
          eventCallback: async (data: any) => {
            const userEmail = data.eventData.user.email || data.checkoutData.email || email
            if (!userEmail) return

            const isSubscribed = await isEmailSubscribedInnerRoute(userEmail)
            if (isSubscribed) {
              document.location.reload()
              return
            }

            switch (data.event) {
              case 'Checkout.Complete': {
                try {
                  if (currentUser) {
                    fpr('referral', { email: currentUser.email })
                  }
                  trackStartTrial(currentUser?.id || '')
                } catch (e) {
                  console.error(e)
                }

                setIsPaymentSuccessful(true)

                const interval = setInterval(async () => {
                  const isSubscribed = await isEmailSubscribedInnerRoute(userEmail)
                  if (isSubscribed) {
                    clearTimeout(timeout)
                    clearInterval(interval)
                    document.location.reload()
                  }
                }, 500)
                const timeout = setTimeout(async () => {
                  clearInterval(interval)
                  setIsPaymentSuccessful(false)
                }, 60 * 1000)

                return
              }
              default:
                return
            }
          },
        })
      }}
    />
  )
}

export default PaddleScript
