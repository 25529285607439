import { sendGTMEvent } from '@next/third-parties/google'

type Metrics = 'TRY_PLATINUM_MODAL_VIEW' | 'EXPORT_CLICK' | 'START_TRIAL_CLICK' | 'UPGRADE_PLAN_CLICK'

export const trackMetricClick = (metric: Metrics, userId?: string, options?: any) => {
  try {
    sendGTMEvent({
      event: 'metric',
      metric,
      userId,
      options,
    })
  } catch (e) {
    console.error(e)
  }
}

export const trackStartTrial = (userId?: string, options?: any) => {
  try {
    sendGTMEvent({
      event: 'start_trial',
      userId,
      options,
    })
  } catch (e) {
    console.error(e)
  }
}
