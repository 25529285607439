import classNames from 'classnames'
import React, { FC, useEffect } from 'react'

import PaymentResultError from '../../../../assets/icons/payment-result-error.svg'
import PaymentResultSuccess from '../../../../assets/icons/payment-result-success.svg'
import { windowOpen } from '../../../../utils/stringUtils'
import LinearLoading from '../../../common/LinearLoader/LinearLoading'
import CenterModal from '../../CenterModal'
import styles from './PaymenResultModal.module.scss'

export type PaymentResultStatus = 'pending' | 'error' | 'success'

interface Props {
  isOpen: boolean
  status: PaymentResultStatus
  message?: string
  onClose: () => void
  paymentDomain?: string
}

const STRINGS = {
  launchCC: 'Launch ChordChord',
  tryAgain: 'Try Again',
  successTitle: 'Payment Successful!',
  successText: '',
  errorTitle: 'Ooops.. Payment Error',
  pendingTitle: 'Payment in Process',
}

const PaymenResultModal: FC<Props> = ({ isOpen, status, message = '', onClose, paymentDomain = '#' }) => {
  useEffect(() => {
    if (status === 'success') windowOpen(paymentDomain, '_parent')
  }, [status])
  const renderSuccessBody = () => {
    return (
      <>
        <div className={styles.innerContainer}>
          <div className={classNames(styles.icon, styles.success)}>
            <PaymentResultSuccess />
          </div>
          <div className={styles.title}>{STRINGS.successTitle}</div>
          <div className={styles.text}>{message}</div>
        </div>
      </>
    )
  }

  const renderErrorBody = () => {
    return (
      <>
        <div className={styles.innerContainer}>
          <div className={classNames(styles.icon, styles.error)}>
            <PaymentResultError />
          </div>
          <div className={styles.title}>{STRINGS.errorTitle}</div>
          <div className={styles.text}>{message}</div>
        </div>

        <div className={styles.btn} onClick={onClose}>
          {STRINGS.tryAgain}
        </div>
      </>
    )
  }

  const renderPendingBody = () => {
    return (
      <div className={styles.innerContainer}>
        <LinearLoading size={210} animate />
        <div className={styles.title}>{STRINGS.pendingTitle}</div>
      </div>
    )
  }

  const renderBody = () => {
    switch (status) {
      case 'pending':
        return renderPendingBody()
      case 'error':
        return renderErrorBody()
      case 'success':
        return renderSuccessBody()
      default:
        return <></>
    }
  }

  return (
    <CenterModal
      visible={isOpen}
      onCancel={() => {
        if (status === 'success') {
          windowOpen(paymentDomain, '_parent')
        }
        onClose()
      }}
      hideClose
      className={styles.modalContent}
      modalClassName={styles.modal}
      zIndex={149}
    >
      <div className={styles.container}>{renderBody()}</div>
    </CenterModal>
  )
}

export default PaymenResultModal
